import Header from "@cospex/client/components/Header";
import Footer from "@cospex/client/tracker/components/Footer";
import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import logo from "../img/logo.svg";

export default function App() {
  const location = useLocation();
  const isHomeOrLocalizationPage =
    location.pathname === "/" || location.pathname.startsWith("/lokalisieren");

  return (
    <>
      <Header
        menu={{
          dashboardHref: "/dashboard/sms",
        }}
        sx={
          isHomeOrLocalizationPage
            ? {
                overflow: "hidden",
                zIndex: 100,
                position: "absolute",
                width: "100%",
              }
            : undefined
        }
      />
      <Outlet />
      <Footer />
    </>
  );
}
