// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/3ds`
  | `/contact`
  | `/cookies`
  | `/dashboard`
  | `/dashboard/account`
  | `/dashboard/history`
  | `/dashboard/sms`
  | `/dashboard/tutorial`
  | `/dashboard/tutorial/iphone`
  | `/dashboard/tutorial/nokia`
  | `/dashboard/tutorial/others`
  | `/dashboard/tutorial/samsung`
  | `/dashboard/tutorial/wiko`
  | `/dashboard/tutorial/xiaomi`
  | `/follow-up`
  | `/geoloc/request/:key/:id`
  | `/locate-android`
  | `/locate-huawei`
  | `/locate-iphone`
  | `/locate-oppo`
  | `/locate-redmi`
  | `/locate-samsung`
  | `/locate-samsung-galaxy`
  | `/locate-samsung-s21`
  | `/locate-samsung-s22`
  | `/locate-xiaomi`
  | `/login`
  | `/payment`
  | `/pricing`
  | `/privacy`
  | `/refunds`
  | `/reset`
  | `/terms`
  | `/unsubscribe`
  | `/update-password`
  | `/valid-payment`

export type Params = {
  '/geoloc/request/:key/:id': { key: string; id: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
